// node_modules dependencies
import Vue from 'vue'
import moment from 'moment'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import filter from 'lodash/filter'
import snakeCase from 'lodash/snakeCase'
import lowerCase from 'lodash/lowerCase'
import split from 'lodash/split'
import join from 'lodash/join'
import debounce from 'lodash/debounce'

@Component
export default class SearchFilter extends Vue {
  constructor() {
    super()
  }

  @Prop({ default: () => [] })
  shipmentMethod: string[]

  @Prop({ default: () => [] })
  status: string[]

  @Prop({ default: true })
  dateFilter: boolean

  @Prop({ default: 'Search by User Name here...' })
  searchLabel: string

  filterPanel: boolean[] = [false]
  filterShipmentItems: string[] = this.shipmentMethod
  filterShipmentSelected: string = ''
  filterItemsStatus: string[] = this.status
  filterSelectedStatus: string = ''
  searchDebounceMethod: Function
  searchInput: string = ''

  // Date Filter
  filterDateStartDialog: boolean = false
  filterDateStartFormatted: string = ''
  filterDateStart: any = null
  filterDateEndDialog: boolean = false
  filterDateEndFormatted: string = ''
  filterDateEnd: any = null
  defaultStartDate: string = '2000-01-01'
  range: any = {
    min: this.defaultStartDate,
    max: this.now()
  }

  get searchOnly() {
    return this.shipmentMethod.length <= 0 && this.status.length <= 0 && !this.dateFilter
  }

  @Watch('filterShipmentSelected', { deep: true })
  @Watch('filterSelectedStatus', { deep: true })
  onDataChanged() {
    this.updateEvent()
  }
  @Watch('searchInput')
  onSearchChanged() {
    this.searchDebounceMethod()
  }

  mounted() {
    this.searchDebounceMethod = debounce(this.updateEvent, 1000)
  }

  now() {
    return moment().format('YYYY-MM-DD')
  }
  toMediumDate(date?: string) {
    return moment(date).format('DD MMM YYYY')
  }

  onFilterDateStartInput(event: any) {
    this.filterDateStart = event
    this.filterDateStartFormatted = this.toMediumDate(event)
  }
  onFilterDateEndInput(event: any) {
    this.filterDateEnd = event
    this.filterDateEndFormatted = this.toMediumDate(event)
    this.filterDateStartFormatted = `${this.toMediumDate(this.filterDateStart)} - ${this.filterDateEndFormatted}`
  }
  onDateStartNext() {
    this.filterDateStartDialog = false
    this.filterDateEndDialog = true
    this.range.min = this.filterDateStart
  }
  onDateStartCancel() {
    this.filterDateStartDialog = false
    this.filterDateStart = null
    this.filterDateStartFormatted = ''
    this.range.min = this.defaultStartDate
    if (this.filterDateEnd !== null) this.updateEvent()
  }
  onDateEndBack() {
    this.filterDateEndDialog = false
    this.filterDateStartDialog = true
    this.filterDateEnd = null
    this.range.min = this.defaultStartDate
  }
  onDateEndDone() {
    this.filterDateEndDialog = false
    this.updateEvent()
  }
  onFilterDateCleared(event: any) {
    if (event === null) {
      this.filterDateStart = null
      this.filterDateEnd = null
      this.updateEvent()
    }
  }

  updateEvent(): any {
    const results: any = {}

    if (this.searchOnly) results.search = this.searchInput
    else {
      // ?search=shipment_method:keyword;status:keyword&combinedWith=keyword&SearchJoin=and
      const keywords = [
        `shipment_method:${snakeCase(this.filterShipmentSelected)}`,
        `status:${lowerCase(this.filterSelectedStatus)}`
      ]
      const filterKeywords = filter(keywords, keyword => {
        return split(keyword, ':')[1] !== ''
      })

      const finalKeyword = join(filterKeywords, ';')
      if (finalKeyword !== '') {
        results.search = finalKeyword
        results.searchJoin = 'and'
      }

      // Date range Search
      if (this.filterDateStart !== null && this.filterDateEnd !== null) {
        results.start_date = this.filterDateStart
        results.end_date = this.filterDateEnd
        results.searchJoin = 'and'
      }
      if (this.searchInput !== '') {
        results.combinedWith = this.searchInput
        results.searchJoin = 'and'
      }
    }

    this.$emit('dataChanged', results)
  }
}
